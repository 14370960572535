import React from "react"
import Layout from "../../../components/layout"
import Seo from "../../../components/seo"
import { StaticImage } from "gatsby-plugin-image"

const LubricantsBlendingAndDistribution = () => {
    return (
        <Layout>
            <Seo title="Lubricants Blending and Distribution" />
            <div className="container py-3 py-lg-4">
                <div className="container py-3 py-lg-4">
                    <div className="row g-0 mb-3">
                        <div className="col border-bottom border-1 border-dark">
                            <h1>Lubricants Blending and Distribution</h1>
                        </div>
                    </div> 
                    <div className="row">
                        <div className="card mb-3 border-0">
                            <div className="row gx-3">
                                <div className="col-md-6 order-2 ">
                                    <div className="card-body services-text d-flex flex-column">
                                        <p className="card-text">
                                            Delta Energies Nigeria has state of art Lubricant blending plant in in heart of Lagos,
                                            Nigeria. We blend & distribute premium quality hi-performance Automotive, Industrial &
                                            Marine Lubricants in heart of West Africa which is the biggest Lubricant market in Africa.
                                        </p>
                                        <p>
                                            Our Lube blending plant is ISO 9001:2015 certified has capacity to blend over 25,000 tons
                                            of various lubricants & speciality oils. It is spread in over 2 acres of land & houses
                                            6,000 MT base oil storage facility. The plant has an in-house state of art Laboratory with
                                            hi-tech equipment’s to ensure international quality standards are maintained. We work
                                            closely with Standard Organization of Nigeria.
                                        </p>
                                        <p>
                                            Our brand HI-SPEED Lubricants enjoys tremendous patronage in automotive & industrial
                                            segments. We only use high quality Virgin base stocks & premium quality additives like
                                            Chevron & Lubrizol in blending our products.
                                        </p>
                                        <p>
                                            We also provide the full spectrum of private label blending services, that includes
                                            customized product formulations, packaging including Labelling & assist 3rd party in
                                            getting SONCAP & NMDPR approvals. We have the capacity to cater to extensive product
                                            portfolio. Numerous Local & international brands depend on us for their Lubricants
                                            requirements.
                                        </p>
                                        <p>
                                            All products manufactured at Delta Nigeria meet and exceed the leading OEM and industrial
                                            specifications and are supported by accreditations from the leading Petroleum bodies’ such
                                            as API and ACEA and OEMs such as MB, VW, VOLVO, and more. We manufacture all types of
                                            lubricants like Automotive Oils, Gear Oils, Transmission Fluids, Hydraulic Oils, Turbine
                                            Oils, Industrial Lubricants, Marine Oils, 2T, 4T and out Board Motor Oils etc.
                                        </p>
                                    </div>
                                </div>
                                <div className="col-md-6 order-1 order-md-2  ">
                                    <StaticImage
                                        className="mb-3 img-responsive"
                                        alt="Sales team"
                                        src="../../../images/service/hispeed2.jpeg"
                                        aspectRatio={16 / 11}
                                        width={640}
                                        layout="fixed"
                                    />
                                    <StaticImage
                                    className="img-responsive"
                                        alt="Sales team"
                                        src="../../../images/service/hispeed1.jpeg"
                                        aspectRatio={16 / 11}
                                        width={640} 
                                        layout="fixed"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default LubricantsBlendingAndDistribution
